import { combineReducers } from '@reduxjs/toolkit'

import GpaTestScoresReducer from './GpaTestScoresSlice'
import GpaReducer from './GpaSlice'
import TestScoresReducer from './TestScoresSlice'

import CurrentlyEnrolledReducer from './CurrentlyEnrolledSlice'
import LastDegreeCompletedReducer from './LastDegreeCompletedSlice'
import LevelOfDegreeSeekingReducer from './LevelOfDegreeSeekingSlice'
import IntendedMajorReducer from './IntendedMajorSlice'
import IntendedAreaofStudyReducer from './IntendedAreaofStudySlice'
import GraduationYearReducer from './GraduationYearSlice'
import StudyAbroadReducer from 'Slices/segments/StudyAbroad'
import ReligiousInstitutionReducer from 'Slices/segments/ReligiousInstitution'
import ExtracurricularsReducer from './ExtracurricularsSlice'
import AthleticsReducer from './AthleticsSlice'



export const prospectProfileReducer = combineReducers({
  gpaTestScores: GpaTestScoresReducer,
  gpa: GpaReducer,
  testScores: TestScoresReducer,
  currentlyEnrolled: CurrentlyEnrolledReducer,
  lastDegreeCompleted: LastDegreeCompletedReducer,
  levelOfDegreeSeeking: LevelOfDegreeSeekingReducer,
  intendedMajors: IntendedMajorReducer,
  intendedAreaofStudy: IntendedAreaofStudyReducer,
  graduationYear: GraduationYearReducer,
  studyAbroad: StudyAbroadReducer,
  religiousInstitution: ReligiousInstitutionReducer,
  extracurriculars: ExtracurricularsReducer,
  athletics: AthleticsReducer
})
