import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import ExtracurricularConfig from 'Configs/fields/extracurriculars.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => (value === 'None Selected' ? '' : value))
}

export const ExtracurricularsSlice = multiSelectSlice(
  ExtracurricularConfig.column,
  'setExtracurriculars',
  ExtracurricularConfig.data,
  ExtracurricularConfig.meta,
  transformValues
)

export const selectExtracurriculars = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.extracurriculars.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label
  } => {
    return {
      selectedValues,
      values: ExtracurricularConfig.data,
      audienceTypes:
        ExtracurricularConfig.source[contractSource].audience_types,
      label: ExtracurricularConfig.meta.label,
    }
  }
)

export const { setExtracurriculars } = ExtracurricularsSlice.actions

export default ExtracurricularsSlice.reducer
