import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { alpha } from '@mui/material'
import {
  Body,
  BodyContent,
} from 'Components/shared/body'
import {
  AddFileButton,
  StyledAddFileButton,
} from 'Components/shared/contracts/deduplicates'
import { Input } from 'Components/shared/input'
import { DialogField, Row } from 'Components/shared/dialog'
import { Loading } from 'Utils/loading'

import useFileUpload from 'Hooks/useFileUpload'

import { FileCategory } from 'Types/index'

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: '20px',
  borderRadius: '4px',
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  height: '40px',
  lineHeight: '18px',
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  boxShadow: `0px 4px 20px ${alpha(theme.palette.common.white, 0.4)}`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.85)}`,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    backgroundColor: 'hsla(216, 79%, 72%, 1)',
  },
  [theme.breakpoints.up('md')]: {
    width: '104px',
  },
}))

const DetailBlock = styled('div')(({ theme }) => ({
  marginTop: '90px',
  marginRight: '-40px',
  marginLeft: '-40px',
  padding: '15px 40px',
  borderRadius: '20px',
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  '& h1': {
    fontSize: '1.25rem',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: '-7px',
  },
  '& ol': {
    paddingLeft: '25px',
    '& li': {
      fontSize: '0.85rem',
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: '20px',
    },
  },
}))

const SmallText = styled('span')(({ theme }) => ({
  fontSize: '0.75rem',
  display: 'block',
  marginTop: '-18px',
  color: theme.palette.text.secondary,
}))

const DialogFieldModWrap = styled('div')(({ theme }) => ({
  [Row]: {
    display: 'block',
  },
  [StyledAddFileButton]: {
    float: 'right',
    marginTop: '-18px',
  },
}))

const FlexWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '32px',
  [Row]: {
    flexGrow: 1,
  },
}))

const AlternateInput = styled(Input)(({ theme }) => ({
  marginBottom: '20px',
  width: '100%',
}))

export const OrangeAnalysis = () => {
  const [uploadData, setUploadData] = useState({
    collegeId: null,
    academicTerm: null,
    academicYear: null,
    fileEvent: null,
  })

  const {
    isUploadingData,
    isFileAdded,
    inputRef,
    handleAddFileClick,
    handleFileChange,
  } = useFileUpload(uploadData.collegeId, FileCategory.OrangeReports)

  return (
    <Body maxWidth={'650px'}>
      <BodyContent>
        <DialogFieldModWrap>
          <DialogField id='college-id' label='College ID'>
            <AlternateInput
              id='college-id'
              name='College ID'
              rules={{
                required: 'College id is a required field',
              }}
              placeholder='College Id'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setUploadData({ ...uploadData, collegeId: e.target.value })
              }
            />
          </DialogField>
          <FlexWrap>
            <DialogField id='academic-term' label='Academic Term'>
              <AlternateInput
                id='academic-term'
                name='Academic Term'
                rules={{
                  required: 'Academic Term is a required field',
                }}
                placeholder='Academic Term'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUploadData({ ...uploadData, academicTerm: e.target.value })
                }
              />
            </DialogField>

            <DialogField id='acadmeic-year' label='Academic Year'>
              <AlternateInput
                id='academic-year'
                name='Academic Year'
                rules={{
                  required: 'Academic Year is a required field',
                }}
                placeholder='Academic Year'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUploadData({ ...uploadData, academicYear: e.target.value })
                }
              />
            </DialogField>
          </FlexWrap>
          <DialogField id='partner-file' label='Upload Updated Partner File'>
            <AddFileButton
              onClick={() => {
                handleAddFileClick()
              }}
            >
              <input
                ref={inputRef}
                type='file'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setUploadData({ ...uploadData, fileEvent: e })
                }}
                style={{ display: 'none' }}
                accept='.csv, .txt'
              />
            </AddFileButton>
            <AlternateInput
              id='updload-partner-file'
              name='upload'
              rules={{
                required: 'College id is a required field',
              }}
              placeholder='College Id'
              value={uploadData?.fileEvent?.target?.files[0]?.name}
            />
            <SmallText>Maximum file size 50 MB</SmallText>
          </DialogField>
        </DialogFieldModWrap>
        <SubmitButton
          onClick={(ev) => {
            return handleFileChange(
              uploadData.fileEvent,
              `${uploadData.collegeId}-${uploadData.academicTerm}-${
                uploadData.academicYear
              }-time-${new Date().getTime()}.csv`,
              uploadData.collegeId
            )
          }}
          disabled={
            !uploadData?.academicTerm ||
            !uploadData?.academicYear ||
            !uploadData?.collegeId ||
            !uploadData?.fileEvent
          }
        >
          Submit
        </SubmitButton>
        {isUploadingData && <Loading/>}
        <DetailBlock>
          <h1>Instructions</h1>
          <ol>
            <li>Copy and paste the required column headers from the header word bank into the Partner funnel file.</li>
            <li>Remove any unused columns.</li>
            <li>Complete the form above. The Orange Report will be accessible the following day in Tableau <a href="https://tableau-analytics.myeab.com/#/site/Cappex/views/OrangeReportDevelopment/OrangeReport">here</a> </li>
          </ol>
        </DetailBlock>
      </BodyContent>
    </Body>
  )
}

//Add back to NavConfig.json
// {
//   "mainNav": "AAR Partner Data",
//   "url": "/admin/aar-partner-data",
//   "subNavs": [],
//   "restricted": true
// }
