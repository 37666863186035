import React, { useMemo, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { alpha } from '@mui/material'
import Button from '@mui/material/Button'
import {
  Body,
  BodyTitle,
  BodyHeader,
  BodyContent,
} from 'Components/shared/body'
import { TablePagination } from 'Containers/shared/tablePagination'
import { TableFooter } from 'Components/shared/table'
import { useGetZipCodesQuery as useGetFilesQuery } from 'Services/configuration/zipCodes'
import { usePagination } from 'Hooks/usePagination'
import { Loading } from 'Utils/loading'
import { Table } from 'Components/shared/table'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import useFileUpload from 'Hooks/useFileUpload'
import { FileCategory } from 'Types/fileCategory'
import { formatFileDate } from 'Utils/dateUtils'
import { DownloadFile } from 'Containers/shared/downloadfile'
import { useParams } from 'react-router-dom'

const DownloadButton = styled(Button)(({ theme }) => ({
  backgroundColor: `${alpha(theme.palette.primary.main, 0.1)}`,
  borderRadius: '4px',
  height: '40px',
  marginRight: `20px`,
  minWidth: '40px'
}))


const columnData = [
  { columnName: 'File Name', columnWidth: 550, sortOrder: 'ASC' },
  { columnName: 'Size In Bytes', columnWidth: 150, sortOrder: 'ASC' },
  { columnName: 'Date Uploaded', columnWidth: 150, sortOrder: 'ASC' },
  { columnName: '', columnWidth: 80 },
]

export const getFilesfilterQuery = {
  query: {
    filterQuery: {
      properties: [
        {
          column: 'category',
          op: 'eq',
          value: FileCategory.Deletion,
        },
      ],
      operation: 'AND',
    },
  },
}

export const Deletions = () => {
  const { currentPage, handlePageChange } = usePagination()
  const { ipedId } = useParams()
  const {
    isFileAdded,
    isUploadingData,
    isUploadingFile,
    inputRef,
    handleAddFileClick,
    handleFileChange,
    setIsFileAdded,
  } = useFileUpload(null, FileCategory.Deletion)

  const { data: fileData, isLoading, refetch } = useGetFilesQuery({
    ipedId: ipedId,
    page: currentPage,
    sortBy: 'updated_at',
    order: 'desc',
    filter: buildFilterQuery(getFilesfilterQuery),
  })

  useEffect(() => {
    if(isFileAdded) {
      refetch()
      setIsFileAdded(false)
    }
  }, [isFileAdded])

  const flattenFiles = useMemo(() => {
    let rows = []
    if (fileData) {
      fileData.records?.forEach((file: any) => {
        rows.push([
          file.id,
          file.name,
          file.size,
          formatFileDate(file.created_at),
          <DownloadFile fileId={file.id} contractDeliveryId={null} instId={ipedId} />,
        ])
      })
    }
    return { rows }
  }, [fileData])

  if (isLoading || isUploadingData || isUploadingFile) return <Loading />

  return (
    <Body>
      <BodyHeader>
        <BodyTitle>Deletions</BodyTitle>
      </BodyHeader>
      <BodyContent>
        {fileData?.records?.length > 0 && (
          <>
            <Table columnData={columnData} rows={flattenFiles.rows} />
            <TableFooter>
              {fileData?.total_pages > 1 && (
                <TablePagination
                  rowsPerPage={10}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  totalRecords={fileData?.total_records}
                  totalPages={fileData?.total_pages}
                />
              )}
            </TableFooter>
          </>
        )}
      </BodyContent>
    </Body>
  )
}