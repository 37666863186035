import {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQuery } from '../getBaseQuery'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'

export interface InstitutionAPIResponse {
  data: {
    id: string
    name: string
    state: string
    country: string
    state_name: string
  }
}

export interface InstitutionResponse {
  id: string
  name: string
  state: string
  country: string
  state_name: string
}

export interface InstitutionSettings {
  institution_gdpr_consent: boolean
}

export interface DeletionNotifications {
  user_id: string
}

const INSTITUTIONS = 'INSTITUTIONS'
const SETTINGS = 'INSTITUTION_SETTINGS'
const DELETIONS = 'DELETIONS'


export const InstitutionsApi = createApi({
  reducerPath: 'institution',
  baseQuery,
  tagTypes: [INSTITUTIONS, SETTINGS, DELETIONS],
  endpoints: (build) => ({
    /** Get iped id and name for all the institutions */
    getInstitutionsInfo: build.query<InstitutionResponse[], void | string>({
      query: (ipedId) => ({ url: ipedId ? `institutions/${ipedId}` : 'institutions'}),
      providesTags: [INSTITUTIONS],
      transformResponse: (response: { data: InstitutionResponse[] }) => {
        const univData = Array.isArray(response.data) ? response.data : [response.data]
        return univData.sort((a, b) => a.name.localeCompare(b.name))
      },
    }),
    getInstitutionSettings: build.query<InstitutionSettings, {ipedId}>({
      query: ({ipedId}) => ({ url: `institutions/${ipedId}/settings` }),
      providesTags: [SETTINGS],
      transformResponse: (response: { data: InstitutionSettings }) => {
        return response.data
      },
    }),
    updateInstitutionSettings: build.mutation<InstitutionSettings, {ipedId, institution_gdpr_consent}>({
      query: ({ipedId, institution_gdpr_consent}) => ({ url: `institutions/${ipedId}/settings`, method: 'PATCH', body: {  institution_gdpr_consent}}),
      invalidatesTags: [SETTINGS],
      transformResponse: (response: { data: InstitutionSettings }) => {
        return response.data
      },
    }),
    createInstitutionSettings: build.mutation<InstitutionSettings, {ipedId, institution_gdpr_consent}>({
      query: ({ipedId, institution_gdpr_consent}) =>({ url: `institutions/${ipedId}/settings`, method: 'POST', body: {  institution_gdpr_consent}}),
      invalidatesTags: [SETTINGS],
      transformResponse: (response: { data: InstitutionSettings }) => {
        return response.data
      },
    }),
    getDeletionNotifications: build.query<DeletionNotifications[], {ipedId}>({
      query: ({ipedId}) => ({ url: `institutions/${ipedId}/deletion-notifications` }),
      providesTags: [DELETIONS],
      transformResponse: (response: { data: DeletionNotifications[] }) => {
        return response.data
      },
    }),
    createDeletionNotifications: build.mutation<DeletionNotifications, {ipedId, user_id_list}>({
      query: ({ipedId, user_id_list}) => ({ url: `institutions/${ipedId}/deletion-notifications`, method: 'POST', body: { user_id_list}}),
      invalidatesTags: [DELETIONS],
      transformResponse: (response: { data: DeletionNotifications }) => {
        return response.data
      },
    }),
    deleteDeletionNotifications: build.mutation<DeletionNotifications, {ipedId, user_id_list}>({
      query: ({ipedId, user_id_list}) => ({ url: `institutions/${ipedId}/deletion-notifications`, method: 'DELETE', body: { user_id_list}}),
      invalidatesTags: [DELETIONS],
      transformResponse: (response: { data: DeletionNotifications }) => {
        return response.data
      },
    }),
  }),
})

export const { useGetInstitutionsInfoQuery, useUpdateInstitutionSettingsMutation, useGetInstitutionSettingsQuery, useCreateInstitutionSettingsMutation, useGetDeletionNotificationsQuery, useCreateDeletionNotificationsMutation, useDeleteDeletionNotificationsMutation } = InstitutionsApi