import { createApi } from '@reduxjs/toolkit/query/react'
import {
  Segment,
  getSegmentsForContract,
  transformSegmentQueryResponse,
  deactivateSegment,
  createSegment,
  getSegment,
  updateSegment,
  getSegmentQueue,
  GetSegmentQueueTransformed,
  transformSegmentQueueResponse,
  deleteSegment,
  processSegment,
} from './segmentUtils'
import { baseQuery } from '../getBaseQuery'

const SEGMENTS = 'SEGMENTS'

export const SegmentsApi = createApi({
  reducerPath: 'segments',
  baseQuery,
  tagTypes: [SEGMENTS],
  endpoints: (build) => ({
    /** gets all segments related to a contract */
    getSegmentsForContract: build.query<
      Array<Segment>,
      {
        ipedId: string
        contractId: string
      }
    >({
      query: getSegmentsForContract,
      providesTags: [SEGMENTS],
      transformResponse: transformSegmentQueryResponse,
    }),

    /** Deactivate a segment */
    deactivateSegment: build.mutation<
      void,
      { ipedId: string; segmentId: string; isActive: boolean }
    >({
      query: deactivateSegment,
      invalidatesTags: [SEGMENTS],
    }),

    /** Create a new segment */
    createSegment: build.mutation<void, any>({
      query: createSegment,
      invalidatesTags: [SEGMENTS],
    }),

    getSegment: build.query<
      Segment,
      {
        ipedId: string
        segmentId: string
      }
    >({
      query: getSegment,
    }),

    updateSegment: build.mutation<void, any>({
      query: updateSegment,
      invalidatesTags: [SEGMENTS],
    }),

    /** Get all segments in queue */
    getSegmentsQueue: build.query<GetSegmentQueueTransformed, { page: number, order: string, searchString: string }>(
      {
        query: getSegmentQueue,
        providesTags: [SEGMENTS],
        transformResponse: transformSegmentQueueResponse,
      }
    ),

    /** Deletes segment */
    deleteSegment: build.mutation<boolean, { ids: string }>({
      query: deleteSegment,
      invalidatesTags: [SEGMENTS],
    }),

    /** Process segment queue orders */
    processSegmentOrder: build.mutation<void, { segment_ids: Array<string> }>({
      query: processSegment,
      invalidatesTags: [SEGMENTS],
    }),
  }),
})

export const {
  useGetSegmentsForContractQuery,
  useLazyGetSegmentsForContractQuery,
  useLazyGetSegmentQuery,
  useDeactivateSegmentMutation,
  useCreateSegmentMutation,
  useUpdateSegmentMutation,
  useGetSegmentsQueueQuery,
  useDeleteSegmentMutation,
  useProcessSegmentOrderMutation,
} = SegmentsApi
