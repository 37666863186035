import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import ProfessionalCredentialConfig from 'Configs/fields/professional_credential.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => (value === 'None Selected' ? '' : value))
}

export const ProfessionalCredentialSlice = multiSelectSlice(
  'professional_credential_list',
  'setProfessionalCredential',
  ProfessionalCredentialConfig.values,
  ProfessionalCredentialConfig.meta,
  transformValues
)

export const selectProfessionalCredential = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.professionalCredentials.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label
  } => {
    return {
      selectedValues,
      values: ProfessionalCredentialConfig.values,
      audienceTypes: ProfessionalCredentialConfig.source[contractSource].audience_types,
      label: ProfessionalCredentialConfig.meta.label,
    }
  }
)

export const { setProfessionalCredential } = ProfessionalCredentialSlice.actions

export default ProfessionalCredentialSlice.reducer
