import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../getBaseQuery'

interface Files {
  id: number
  delivered_date: string
  file_name: string
  audience_type: string
  status: string
}

interface GetFilesApiResponse {
  data: {
    records: Array<Files>
    total_pages: number
    total_records: number
    current_page: number
    current_size: number
  }
}

interface GetFilesTransformedResponse {
  records: Array<Files>
  total_pages: number
  total_records: number
}

interface DownloadResponse {
  data: string
}

export enum FileStatus {
  failed = 'failed',
  undelivered = 'success_no_delivery',
}

const FILES = 'FILES'

export const FilesApi = createApi({
  reducerPath: 'files',
  baseQuery,
  tagTypes: [FILES],
  endpoints: (build) => ({
    getFiles: build.query<
      GetFilesTransformedResponse,
      { ipedId: string | null; page: number; order: string; filter?: string | object }
    >({
      query: (args) => ({
        url: `institutions/${args.ipedId}/contract-deliveries?page=${args.page}&sort_by=delivered_date::${args.order}&filter=${args.filter}`,
      }),
      providesTags: [FILES],
      transformResponse: (response: GetFilesApiResponse) => {
        const files: GetFilesTransformedResponse = {
          total_pages: response.data.total_pages,
          total_records: response.data.total_records,
          records: response.data.records,
        }
        return files
      },
    }),

    getDownloadFile: build.mutation<
      DownloadResponse,
      { ipedId: string; contractDeliveryId: string | null, fileId?: string | null }
    >({
      query: (args) => ({
        url: `institutions/${args.ipedId}/${args.contractDeliveryId ? 'contract-deliveries/' + args.contractDeliveryId : 'files/' + args.fileId }/_download_url`,
      }),
      invalidatesTags: [FILES],
    }),
  }),
})

export const { useGetFilesQuery, useGetDownloadFileMutation } = FilesApi
