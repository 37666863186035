import { FilterBy } from 'Filter/properties'
import { dateRangeToYearRange } from 'Utils/dateUtils'
import AreaOfStudyConfig from 'Configs/fields/area_of_study.json'
import AreaOfInteresConfig from 'Configs/fields/area_of_interest.json'
import MajorConfig from 'Configs/fields/majors.json'
import ExpectedTransferTerm from 'Configs/fields/expected_transfer_term.json'
import AgeConfig from 'Configs/fields/age.json'
import ExperienceConfig from 'Configs/fields/years_of_work_experience.json'

const mapAnyToFilterValue = (anyValue: string, inputArray: string[]) => {
  let result = []

  inputArray.forEach((input: string) => {
    if (input === '') {
      result.push(anyValue)
    } else {
      result.push(input)
    }
  })

  return result
}

export function mapDetailsToFilter(filter, result) {
  if (!result) result = {}
  if (filter?.meta?.section && filter?.meta?.label) {
    let value = filter.value
    if (['birth_date', 'years_of_work_experience'].includes(filter.column)) {
      value = value ? [Math.abs(value[1]), Math.abs(value[0])] : value
      if(value === null) {
        if(filter.column === 'birth_date') {
          value = AgeConfig.meta.nullLabel
        }
        if(filter.column === 'years_of_work_experience') {
          value = ExperienceConfig.meta.nullLabel
        }
      }
    }
    if (filter.column === 'high_school_grad_date')
      value = dateRangeToYearRange(value[0], value[1])
    if (filter.column === 'state')
      value = { selectedValue: filter.meta.selectedValue, states: value }
    if (filter.column === 'zip_code') {
      value = value?.map((zipCode: string) =>
        zipCode?.toString().replace(/%/, '')
      )
      value = {
        selectedValue: filter.meta.selectedValue,
        zipCodes: value.join(','),
      }
    }
    if (filter.column === 'country' && filter.op === 'ne')
      value = { selectedValue: filter.meta.selectedValue }

    if (filter.column === AreaOfStudyConfig.column) {
      let areaOfStudyList = []

      value.forEach((value: string) => {
        if (value === '') {
          areaOfStudyList.push(AreaOfStudyConfig.no_selection)
        } else {
          AreaOfStudyConfig.data.forEach((areaOfStudy: string) => {
            if (value === areaOfStudy.split(' - ')[1]?.trim()) {
              areaOfStudyList.push(areaOfStudy)
            }
          })
        }
      })

      value = areaOfStudyList
    }

    if (filter.column === MajorConfig.column) {
      let majors = []

      value.forEach((value: string) => {
        if (value === '') {
          majors.push(MajorConfig.no_selection)
        } else {
          MajorConfig.data.forEach((major: string) => {
            if (value === major) {
              majors.push(major)
            }
          })
        }
      })

      value = majors
    }

    if (filter.column === AreaOfInteresConfig.column) {
      let areaOfInterestList = []

      value.forEach((value: string) => {
        if (value === '') {
          areaOfInterestList.push(AreaOfInteresConfig.no_selection)
        } else {
          AreaOfInteresConfig.data.forEach((areaOfInterest: string) => {
            if (value === areaOfInterest.split(' - ')[1]?.trim()) {
              areaOfInterestList.push(areaOfInterest)
            }
          })
        }
      })

      value = areaOfInterestList
    }

    if (filter.column === ExpectedTransferTerm.column) {
      let terms = []
      value.forEach((value: string) => {
        if (value === '') {
          terms.push(ExpectedTransferTerm.no_selection)
        } else {
          ExpectedTransferTerm.data.forEach((term: string) => {
            if (value === term) {
              terms.push(term)
            }
          })
        }
      })
      value = terms
    }

    if (filter.column === 'level_of_degree_seeking') {
      value = mapAnyToFilterValue('Any', value)
    }

    if (filter.op === FilterBy.RANGE) value = [value[0], value[1]]

    if ((filter.column === 'hs_gpa' || filter.column === 'college_gpa') && value === null) {
      value = 'No GPA Available'
    }
    result[filter.meta.label] = result[filter.meta.label] && result[filter.meta.label] !== 'Any' ? [result[filter.meta.label], value] : value
  }
  if (filter?.properties) {
    filter?.properties?.forEach((property) => {
      mapDetailsToFilter(property, result)
    })
  }
    if(filter?.column === 'age_range_as_of_today_max' && result['Age'] && !result['Age'].includes('Include Dynamic Age Range')) {
      result['Age'] = [result['Age'], 'Include Dynamic Age Range']
    }
  return result
}
