import React, { useCallback, useEffect, memo } from 'react'
import { useToggle } from 'Hooks/useToggle'
import { Accordion } from 'Components/shared/accordion'
import { SidebarFieldWrapper } from 'Components/shared/sidebar'
import { useDispatch, useSelector } from 'react-redux'

import { setTimeBasedGender, selectGender } from './GenderSlice'
import {
  setTimeBasedAgeRange,
  setTimeBasedAgeRangeSelection,
  setAgeRangeDefaultValue,
  selectAge,
} from './AgeSlice'

import { Slider } from 'Components/shared/slider'
import { SidebarField } from 'Components/shared/sidebar'

import { Geography } from './Geography'
import { VirtualizedMultiSelect } from 'Components/shared/multiselectdropdown'
import { Contract } from 'Services/contracts/contractsUtils'

interface DemographicsProps {
  chosenItem: Partial<Contract>
  filterData?: any
  segmentId?: string
  control?: any
  resetKey: number
}

export const Demographics = memo(
  ({
    chosenItem,
    filterData,
    segmentId,
    control,
    resetKey,
  }: DemographicsProps) => {
    const dispatch = useDispatch()
    const [expand, setExpand] = useToggle(false)

    const age = useSelector(selectAge)
    const gender = useSelector(selectGender)

    /** Set default age based on the audience type */
    useEffect(() => {
      const audienceType = chosenItem?.audience_type
      setAgeRangeDefaultValue(dispatch, audienceType)
    }, [chosenItem?.id, resetKey])

    /** This useEffect is for setting filters in duplicated segment */
    useEffect(() => {
      if (segmentId) {
        if (filterData?.[gender.label]) {
          dispatch(setTimeBasedGender(filterData[gender.label]))
        }
        if (filterData?.[age.label]) {
          const selections = filterData[age.label]
          dispatch(setTimeBasedAgeRangeSelection({selections}))
        }
      }
    }, [filterData])

    const dispatchAgeRange = useCallback(({ min, max }) => {
      dispatch(setTimeBasedAgeRange({ min, max }))
    }, [])

    const dispatchGender = useCallback((values: string[]) => {
      dispatch(setTimeBasedGender(values))
    }, [])

    return (
      <Accordion
        setExpand={setExpand}
        expand={expand}
        title='DEMOGRAPHICS'
        disabled={chosenItem?.id ? false : true}
      >
        <SidebarFieldWrapper>
          <Geography control={control} filterData={filterData} />
          {age.audienceTypes.includes(chosenItem?.audience_type) && (
            <>
            <VirtualizedMultiSelect
              label={age.label}
              counterLabel="Selections"
              values={age.values}
              selectedValues={age.selections}
              initialSelectedValues={age.selections}
              setSelectedValues={(selections) => dispatch(setTimeBasedAgeRangeSelection({selections}))}
              />
            {age.selections.includes('Specific Age Range') && <SidebarField id='age' label='Age'>
              <Slider
                ariaLabel={age.ariaLabel}
                min={age.minAge}
                max={age.maxAge}
                selectedMin={age.selectedMinAge}
                selectedMax={age.selectedMaxAge}
                step={age.step}
                handleChange={(range: number[]) =>
                  dispatchAgeRange({ min: range[0], max: range[1] })
                }
              />
            </SidebarField>}
            </>
          )}
          <VirtualizedMultiSelect
            label='Gender'
            counterLabel={'Gender'}
            values={gender.values}
            initialSelectedValues={gender.selectedValues}
            selectedValues={gender.selectedValues}
            setSelectedValues={dispatchGender}
          />
        </SidebarFieldWrapper>
      </Accordion>
    )
  }
)
