import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import AthleticsConfig from 'Configs/fields/athletics.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => (value === 'None Selected' ? '' : value))
}

export const AthleticsSlice = multiSelectSlice(
  AthleticsConfig.column,
  'setAthletics',
  AthleticsConfig.data,
  AthleticsConfig.meta,
  transformValues
)

export const selectAthletics = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.athletics.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label
  } => {
    return {
      selectedValues,
      values: AthleticsConfig.data,
      audienceTypes: AthleticsConfig.source[contractSource].audience_types,
      label: AthleticsConfig.meta.label,
    }
  }
)

export const { setAthletics } = AthleticsSlice.actions

export default AthleticsSlice.reducer
