import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'

interface SegmentQueueState {
  selectAll: boolean
  selectedSegments: any[]
}

const initialState: SegmentQueueState = {
  selectAll: false,
  selectedSegments: [],
}

const SegmentQueueSlice = createSlice({
  name: 'segmentQueueSlice',
  initialState,
  reducers: {
    setSelectAll: (state, action: PayloadAction<boolean>) => {
      state.selectAll = action.payload
    },
    setSelectedSegments: (state, action: PayloadAction<{segments: any[]}>) => {
      const { segments } = action.payload
      if(state.selectedSegments.length > segments.length && state.selectAll){
        state.selectAll = false
      }
      state.selectedSegments = segments
    },
    clearSelected: () => {
      return initialState
    }
  }
})

export const selectSegmentQueue = createSelector(
  ( state : RootState) => state.segmentQueue.selectAll,
  ( state : RootState) => state.segmentQueue.selectedSegments,
  (
    selectAll,
    selectedSegments,
  ): SegmentQueueState => {
    return {
      selectAll,
      selectedSegments,
    }
  }
)

export const {
  setSelectAll,
  setSelectedSegments,
  clearSelected
} = SegmentQueueSlice.actions

export default SegmentQueueSlice.reducer