import React, {useState} from 'react'
import styled from 'styled-components'
import Checkbox from '@mui/material/Checkbox'
import {
  Body,
  BodyTitle,
  BodyActions,
  BodyHeader,
  BodyContent,
} from 'Components/shared/body'
import { DefaultButton } from 'Components/shared/buttons'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { Loading } from 'Utils/loading'
import { useInstitutionSettings } from 'Hooks/institution'
import CheckboxUnchecked from 'Assets/images/checkbox_unchecked.svg'
import CheckboxChecked from 'Assets/images/checkbox_checked.svg'
import { Table } from 'Components/shared/table'

// Column data configuration
const columnData = [
  { columnName: 'Name', columnWidth: 260, sortOrder: 'ASC' },
  { columnName: 'Email', columnWidth: 250, sortOrder: 'ASC' },
]

const FormSection = styled('div')(({ theme }) => ({
  marginBottom: '55px',
}))

const Card = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: '8px',
  boxShadow: '0px 2px 4px 0px #0000001A',
  marginBottom: '48px',
  padding: '44px 0px',
  paddingLeft: '56px',
  width: '1074px',
  [theme.breakpoints.up('md')]: {
    display: 'block',
    width: '100%',
  },
  h2: {
    fontSize: '20px',
    fontWeight: 500,
    marginBottom: '30px',
  },
}))


export const InstitutionSettings = () => {
  const { hasPermission: canSwitchInstitution } = useHasPermission(Permissions.SwitchInstitution)
  const { setIsGDPR, setNotifiedUsers, isChanged, isLoading, saveSettings, institution_gdpr_consent, notifiedUsers, allUsers, isNotificationsLoading} = useInstitutionSettings()
  const [isRowChanged, setRowChange] = useState(false)

  const handleRowClick = (rowIdx: number, userId) => {
    setRowChange(true)
    const newUsers = [...notifiedUsers]
    if(newUsers.includes(userId)){
      newUsers.splice(newUsers.indexOf(userId), 1)
    } else {
      newUsers.push(userId)
    }
    setNotifiedUsers(newUsers)
  }

  const handleAllClick = (flag: boolean) => {
    if(notifiedUsers.length === allUsers.length){
      setNotifiedUsers([])
    } else {
      setNotifiedUsers(allUsers.map((user) => user[0]))
    }
  }


  return (
    <Body>
      <BodyHeader>
        <BodyTitle>Institution Settings</BodyTitle>
        <BodyActions>
          {canSwitchInstitution && (
            <DefaultButton disabled={!isChanged && !isRowChanged} onClick={saveSettings}>Update</DefaultButton>
          )}
        </BodyActions>
      </BodyHeader>
      <BodyContent>
      <Card>
      {isLoading && (<Loading />)}
      <FormSection>
        <h2>GDPR Consent</h2>
        <Checkbox
          icon={<CheckboxUnchecked />}
          checkedIcon={<CheckboxChecked />}
          checked={institution_gdpr_consent}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setIsGDPR(e.target.checked)
          }}
        />
          Is GDPR Consent required?
        </FormSection>
        <FormSection>
        <h2>Notification Configuration for deletion request</h2>
        <Table
            width='100%'
            columnData={columnData}
            rows={allUsers}
            variant='striped'
            isSelectable={true}
            selectedAll={notifiedUsers.length === allUsers.length}
            handleAllClick={handleAllClick}
            rowIndexes={notifiedUsers}
            handleRowClick={handleRowClick}
            disabled={isNotificationsLoading}
            handleCheckboxClick={handleRowClick}
          />
        </FormSection>
        </Card>
      </BodyContent>
    </Body>
  )
}
