import React, { useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  ContractSection,
  ContractSectionHeader,
  ContractConfigurationFieldContainer,
} from 'Components/shared/contracts'
import { DialogField } from 'Components/shared/dialog'
import { TimeBasedDeliveryFields } from 'Components/connectedComponents/contracts'
import { VirtualizedMultiSelect } from 'Components/shared/multiselectdropdown'
import { SingleSelectDropdown } from 'Components/shared/singleselectdropdown'
import { useGetUsersQuery } from 'Services/configuration/users'
import { useGetAllDestinationsQuery } from 'Services/configuration/destinations'
import { useGetSupportedColumnsQuery } from 'Services/global/introspection'
import { TransferList } from 'Components/shared/transferlist'
import { Table } from 'Components/shared/table'
import { Divider } from 'Components/shared/divider'
import { Loading } from 'Utils/loading'
import { FileFormat, LookBackPeriod } from 'Types/index'
import { ContractFormProps } from './types'
import {
  useGetDestinationsForContractQuery,
  useGetSubscribedNotificationsQuery,
} from 'Services/contracts/Contracts'
import {
  selectContractConfiguration,
  setContractConfiguration,
} from 'Slices/contracts/ContractConfigurationSlice'
import {
  selectContractState,
  setContractState,
} from 'Slices/contracts/ContractStateSlice'

// Column data configuration
const columnData = [
  { columnName: 'Name', columnWidth: 260, sortOrder: 'ASC' },
  { columnName: 'Email', columnWidth: 250, sortOrder: 'ASC' },
]

const DeliveryCriteriaComponent = ({ control }: ContractFormProps) => {
  const theme = useTheme()
  const hasMdBreakpoint = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const { contractId: contractIdParam, ipedId } = useParams()
  const {
    contractType,
    contractSource,
    contractId,
    contractInstitutionId,
    isDisabledContract,
    selectedDestinations,
    selectedFileFormat,
    selectedFields,
    selectedLookbackPeriod,
    segment_fill_type
  } = useSelector(selectContractConfiguration)
  const {
    fieldsAvailableForContract,
    allDestinations,
    allFileFormats,
    allLookbackPeriods,
  } = useSelector(selectContractState)

  const isExistingContract = !!contractIdParam
  const isNewContract = !isExistingContract
  const disabledEditing = isDisabledContract || !contractSource || !contractType

  const [allUsers, setAllUsers] = useState([])
  const [rowIdxs, setRowIdxs] = useState<boolean[]>([])
  const [selectAll, setSelectAll] = useState(true)

  const { data: usersInfo, isLoading: isLoadingUserInfo } = useGetUsersQuery(
    contractInstitutionId,
    { skip: !contractInstitutionId }
  )
  const { data: supportedColumns, isLoading: isLoadingSupportedColumns } =
    useGetSupportedColumnsQuery()
  const {
    data: availableDestinations,
    isLoading: isLoadingAvailableDestinations,
  } = useGetAllDestinationsQuery({
    ipedId,
  })

  const { data: destinationsInfo, isLoading: isLoadingDestinations } =
    useGetDestinationsForContractQuery(
      { ipedId, contractId: contractIdParam },
      { skip: !ipedId || !contractIdParam }
    )

  const {
    data: allSubscribedNotifications,
    isLoading: isLoadingSubscibedNotifications,
  } = useGetSubscribedNotificationsQuery(
    { ipedId, contractId: contractIdParam },
    { skip: !ipedId || !contractIdParam }
  )

  useEffect(() => {
    if (usersInfo) {
      const rows = usersInfo.map((user: any) => [
        '',
        `${user.first_name} ${user.last_name}`,
        user.email,
      ])
      setAllUsers(rows)
      if (isNewContract) {
        setRowIdxs(new Array(rows.length).fill(true))
        dispatch(
          setContractConfiguration({
            selectedLeads: usersInfo.map((user: any) => user.id),
          })
        )
      }
    }
  }, [usersInfo])

  useEffect(() => {
    if (allSubscribedNotifications && isExistingContract) {
      const notifications = []
      const rowIndexes = []
      allSubscribedNotifications.data.forEach((notification: any) => {
        rowIndexes.push(notification.is_enabled)
        if (notification.is_enabled) notifications.push(notification.id)
      })
      setRowIdxs(rowIndexes)
      dispatch(
        setContractConfiguration({
          selectedLeads: notifications.length ? notifications : [],
          isClean: true,
        })
      )
    }
  }, [allSubscribedNotifications])

  useEffect(() => {
    if (availableDestinations) {
      dispatch(setContractState({ allDestinations: availableDestinations }))
    }
  }, [availableDestinations])

  useEffect(() => {
    if (destinationsInfo) {
      dispatch(
        setContractConfiguration({
          selectedDestinations: destinationsInfo?.data,
          isClean: true,
        })
      )
    }
  }, [destinationsInfo])

  const handleAllClick = (flag: boolean) => {
    setRowIdxs(rowIdxs.map(() => flag))
    dispatch(
      setContractConfiguration({
        selectedLeads: flag ? usersInfo.map((user: any) => user.id) : [],
      })
    )
  }

  const handleRowClick = (rowIdx: number) => {
    const newRows = [...rowIdxs]
    newRows[rowIdx] = !newRows[rowIdx]
    setRowIdxs(newRows)

    const selectedIds = usersInfo
      .filter((_, idx) => newRows[idx])
      .map((user: any) => user.id)
    dispatch(
      setContractConfiguration({
        selectedLeads: selectedIds,
      })
    )
  }

  const handleSelectedDestinations = (selectedDestinationLabels: string[]) => {
    const selectedDestinations = allDestinations.filter((destination: any) =>
      selectedDestinationLabels.includes(destination.label)
    )
    dispatch(
      setContractConfiguration({
        selectedDestinations: selectedDestinations,
      })
    )
  }

  const handleSelectedDeliveryCadence = (selectedCadenceLabels: string[]) => {
    if (selectedCadenceLabels.length) {
      dispatch(
        setContractConfiguration({
          selectedDeliveryCadence: selectedCadenceLabels,
        })
      )
    }
  }

  if (
    isLoadingDestinations ||
    isLoadingAvailableDestinations ||
    isLoadingUserInfo ||
    isLoadingSupportedColumns ||
    isLoadingSubscibedNotifications
  ) {
    return <Loading />
  }
  return (
    <ContractSection>
      <ContractSectionHeader label='Delivery Criteria' />
      <ContractConfigurationFieldContainer>
        <DialogField id='add-delivery-destination' label='Delivery Destination'>
          <VirtualizedMultiSelect
            label=''
            counterLabel='Destinations'
            values={
              allDestinations?.map((destination: any) => destination?.label) ||
              []
            }
            initialSelectedValues={
              selectedDestinations?.map(
                (destination: any) => destination?.label
              ) || []
            }
            selectedValues={
              selectedDestinations?.map(
                (destination: any) => destination?.label
              ) || []
            }
            setSelectedValues={handleSelectedDestinations}
            disabled={disabledEditing}
          />
        </DialogField>
        <DialogField id='add-file-format' label='*File Format to Deliver'>
          <SingleSelectDropdown
            id='add-file-format-select'
            name='fileFormat'
            style={hasMdBreakpoint ? { width: '279px' } : {}}
            rules={{ required: 'File Format is a required field' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(
                setContractConfiguration({
                  FileFormat: e.target.value as FileFormat,
                })
              )
            }
            value={selectedFileFormat}
            values={allFileFormats}
            disabled={disabledEditing}
          />
        </DialogField>

        {fieldsAvailableForContract.includes('delivery_lookback') && (
          <DialogField id='add-delivery-lookback' label='*Delivery Look-Back'>
            <SingleSelectDropdown
              id='add-delivery-lookback'
              name='deliveryLookback'
              style={hasMdBreakpoint ? { width: '279px' } : {}}
              rules={{ required: 'Delivery Look-Back is a required field' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(
                  setContractConfiguration({
                    selectedLookbackPeriod: LookBackPeriod.toNumberOfDays(
                      e.target.value
                    ),
                  })
                )
              }
              values={allLookbackPeriods}
              value={LookBackPeriod.toHumanName(selectedLookbackPeriod)}
              disabled={disabledEditing}
            />
          </DialogField>
        )}

        <DialogField id='segment-priority' label='Segment Priority'>
          <SingleSelectDropdown
            id='add-segment-priority'
            name='segmentPriority'
            style={hasMdBreakpoint ? { width: '279px' } : {}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(
                setContractConfiguration({
                  segment_fill_type: e.target.value as FileFormat,
                })
              )
            }
            value={segment_fill_type}
            values={['least_delivered', 'manual_sort']}
            disabled={disabledEditing}
          />
        </DialogField>

        {contractType === 'annual' && <TimeBasedDeliveryFields control={control} />}
        <DialogField id='divider' label=''>
          <Divider />
        </DialogField>

        <DialogField
          id='add-fields-to-export'
          label='Fields to Export'
          alignSelf='flex-start'
        >
          <TransferList
            allItems={supportedColumns}
            exportedItems={selectedFields}
            exportItems={(selectedItems: string[]) =>
              dispatch(
                setContractConfiguration({ selectedFields: selectedItems })
              )
            }
            disabled={disabledEditing || supportedColumns?.length === 0}
          />
        </DialogField>
        <DialogField id='divider' label=''>
          <Divider />
        </DialogField>
        <DialogField
          id='automatic-lead-report'
          label='Automatic Lead Report Notification'
          alignSelf='flex-start'
        >
          <Table
            width='516px'
            height='248px'
            columnData={columnData}
            rows={allUsers}
            variant='striped'
            isSelectable={true}
            selectedAll={rowIdxs.every((elem) => elem === true)}
            handleAllClick={handleAllClick}
            rowIndexes={rowIdxs}
            handleRowClick={handleRowClick}
            disabled={disabledEditing}
            handleCheckboxClick={(_, idx: number) => handleRowClick(idx)}
          />
        </DialogField>
      </ContractConfigurationFieldContainer>
    </ContractSection>
  )
}

export const ContractDeliveryCriteria = memo(DeliveryCriteriaComponent)
