import { ContractType, ContractSource, ContractStatus } from 'Types/index'

export interface Contract {
  id: string
  ipedId: string
  institution_id?: string
  created_at: string
  updated_at: string
  label: string
  contract_type: ContractType
  audience_type: string
  purchase_type?: string
  salesforce_id: string
  lead_cap: number
  source?: ContractSource
  start_date: string
  end_date: string
  notes: string
  status: ContractStatus
  fields_to_export: string[]
  delivery_cadence: string[]
  cap_settings?: string
  delivery_model: string
  look_back_period?: number
  delivery_quota_cap?: number
  manual_segment_order: string[]
  segment_fill_type: string
}
/** Get all contracts for an institution */

export interface GetContractsApiResponse {
  data: GetContractsTransformedResponse
}

export interface GetContractsTransformedResponse {
  records: Array<Contract>
  total_pages: number
  total_records: number
}

export const transformContractsQueryResponse = (
  response: GetContractsApiResponse
) => {
  const contracts: GetContractsTransformedResponse = {
    records: response.data.records,
    total_pages: response.data.total_pages,
    total_records: response.data.total_records,
  }
  return contracts
}

export const contractLabelSearchQuery = (label: string) => ({
  query: {
    filterQuery: {
      properties: [
        {
          properties: [
            {
              column: 'label',
              op: 'eq',
              value: label,
            },
          ],
          operation: 'AND',
        },
      ],
      operation: 'AND',
    },
  },
})

export const contractsQuery = ({ ipedId, page, size, order, filter }) => ({
  url: `institutions/${ipedId}/contracts?page=${page}&size=${size}&sort_by=audience_type::${order}&filter=${filter}`,
})

/** Get a contract by id */

export interface ContractApiResponse {
  data: Contract
}

export const transformContractByIdQueryResponse = (
  response: ContractApiResponse
): Contract => {
  return response.data
}

export const getContractByIdquery = ({ ipedId, contractId }) => ({
  url: `institutions/${ipedId}/contracts/${contractId}`,
})

export const searchContractByLabel = ({ ipedId, filterQuery }) => ({
  url: `institutions/${ipedId}/contracts?filter=${filterQuery}`,
})

export const getSubscribedNotifications = ({ ipedId, contractId }) => ({
  url: `institutions/${ipedId}/contracts/${contractId}/notifications`,
})

export const createContract = ({ ipedId, ...args }) => {
  if(args.contract_type === 'on_demand') {
    args.salesforce_id = null
    args.lead_cap = null
  }
  return {
  url: `institutions/${ipedId}/contracts`,
  method: 'POST',
  body: {...args,
    segment_fill_type: args.segment_fill_type || 'least_delivered',
    segment_match_order: args.segment_match_order || [],

  },
}}

export const addDestinationsToContract = ({
  ipedId,
  contractId,
  destinationIds,
}) => ({
  url: `institutions/${ipedId}/contracts/${contractId}/destinations`,
  method: 'PATCH',
  body: { destination_ids: destinationIds },
})

export const getUserContractNotifications = ({ ipedId, contractId }) => ({
  url: `institutions/${ipedId}/contracts/${contractId}/notifications`,
  method: 'GET',
})

export const subscribeUsertoContractNotifications = ({
  ipedId,
  contractId,
  accountIdsEnabled,
}) => ({
  url: `institutions/${ipedId}/contracts/${contractId}/notifications`,
  method: 'PATCH',
  body: { account_ids_enabled: accountIdsEnabled },
})

export const updateContract = ({ ipedId, contractId, contract }) => {
  if(contract.contract_type === 'on_demand') {
    contract.salesforce_id = null
    contract.lead_cap = null
  }
  return {
  url: `institutions/${ipedId}/contracts/${contractId}`,
  method: 'PUT',
  body: {...contract,
    segment_fill_type: contract.segment_fill_type || 'least_delivered',
    segment_match_order: contract.segment_match_order || [],

  },
}}
