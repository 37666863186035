import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import SliceConfig from 'Configs/fields/instructional_level.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => (value === 'None Selected' ? '' : value))
}

export const InstructionalLevelSlice = multiSelectSlice(
  'instructional_level_list',
  'setInstructionalLevel',
  SliceConfig.values,
  SliceConfig.meta,
  transformValues
)

export const selectInstructionalLevel = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.instructionalLevel.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label
  } => {
    return {
      selectedValues,
      values: SliceConfig.values,
      audienceTypes: SliceConfig.source[contractSource].audience_types,
      label: SliceConfig.meta.label,
    }
  }
)

export const { setInstructionalLevel } = InstructionalLevelSlice.actions

export default InstructionalLevelSlice.reducer