import React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

export const AddFile = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: '14px',
  padding: '0px',
  '&:active': {
    position: 'relative',
    top: '1px',
    left: '1px',
  },
}))

interface AddFileButtonProps {
  /** function that handles Add File button click */
  onClick: (e) => void
  /**  file upload input passed as children */
  children: React.ReactNode
}

export const AddFileButton = ({ onClick, children }: AddFileButtonProps) => {
  return (
    <AddFile onClick={onClick}>
      Add File
      {children}
    </AddFile>
  )
}