import React, { useMemo, useEffect } from 'react'
import {
  Body,
  BodyTitle,
  BodyActions,
  BodyHeader,
  BodyContent,
} from 'Components/shared/body'
import { TablePagination } from 'Containers/shared/tablePagination'
import { TableFooter } from 'Components/shared/table'
import { AddFileButton } from 'Components/shared/buttons/AddfileButton'
import { useGetZipCodesQuery as useGetFilesQuery } from 'Services/configuration/zipCodes'
import { usePagination } from 'Hooks/usePagination'
import { Loading } from 'Utils/loading'
import { Table } from 'Components/shared/table'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import useFileUpload from 'Hooks/useFileUpload'
import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { FileCategory } from 'Types/fileCategory'
import { formatFileDate } from 'Utils/dateUtils'


const columnData = [
  { columnName: 'File Name', columnWidth: 550, sortOrder: 'ASC' },
  { columnName: 'Size In Bytes', columnWidth: 150, sortOrder: 'ASC' },
  { columnName: 'Date Uploaded', columnWidth: 150, sortOrder: 'ASC' },
]

export const getFilesfilterQuery = {
  query: {
    filterQuery: {
      properties: [
        {
          column: 'category',
          op: 'eq',
          value: FileCategory.Deletion,
        },
      ],
      operation: 'AND',
    },
  },
}

export const DeletionUpload = () => {
  const { currentPage, handlePageChange } = usePagination()
  const {
    isFileAdded,
    isUploadingData,
    isUploadingFile,
    inputRef,
    handleAddFileClick,
    handleFileChange,
    setIsFileAdded,
  } = useFileUpload(null, FileCategory.Deletion)
  const { hasPermission: canUploadFile } = useHasPermission(
    Permissions.SwitchInstitution
  )

  const { data: fileData, isLoading, refetch } = useGetFilesQuery({
    ipedId: null,
    page: currentPage,
    sortBy: 'updated_at',
    order: 'desc',
    filter: buildFilterQuery(getFilesfilterQuery),
  })

  useEffect(() => {
    if(isFileAdded) {
      refetch()
      setIsFileAdded(false)
    }
  }, [isFileAdded])

  const flattenFiles = useMemo(() => {
    let rows = []
    if (fileData) {
      fileData.records?.forEach((file: any) => {
        rows.push([
          file.id,
          file.name,
          file.size,
          formatFileDate(file.created_at)
        ])
      })
    }
    return { rows }
  }, [fileData])

  if (isLoading || isUploadingData || isUploadingFile) return <Loading />

  return (
    <Body>
      <BodyHeader>
        <BodyTitle>Deletion Upload</BodyTitle>
        <BodyActions>
          {canUploadFile && (
            <AddFileButton onClick={handleAddFileClick}>
              <input
                ref={inputRef}
                type='file'
                onChange={handleFileChange}
                style={{ display: 'none' }}
                accept='.csv, .txt'
              />
            </AddFileButton>
          )}
        </BodyActions>
      </BodyHeader>
      <BodyContent>
        {fileData?.records?.length > 0 && (
          <>
            <Table columnData={columnData} rows={flattenFiles.rows} />
            <TableFooter>
              {fileData?.total_pages > 1 && (
                <TablePagination
                  rowsPerPage={10}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  totalRecords={fileData?.total_records}
                  totalPages={fileData?.total_pages}
                />
              )}
            </TableFooter>
          </>
        )}
      </BodyContent>
    </Body>
  )
}