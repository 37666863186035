import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import {
  ContractSource,
  ContractStatus,
  ContractType,
  FileFormat,
  LookBackPeriod,
  PurchaseType,
} from 'Types/index'
import { AudienceTypeHelper } from 'Types/index'
import type { RootState } from 'Utils/store'
import { Destination } from 'Services/configuration/destinations'
import { resetContractStates, allDeliveryModelValues, deliveryModelKeyToValue, deliveryModelValueToKey } from 'Slices/contracts'

export interface ContractConfigurationState {
  contractId?: string
  contractName: string
  contractType: ContractType
  contractSource: ContractSource
  contractInstitutionId?: string
  status: ContractStatus
  isDisabledContract: boolean
  purchaseType: PurchaseType
  audienceType: string
  salesforceID: string
  startDate: string
  endDate: string
  leadCap?: number
  segmentLeadCapTotal?: number
  notes: string
  selectedDestinations: Destination[]
  selectedFileFormat: FileFormat
  selectedFields: string[]
  selectedDeliveryCadence: string[]
  selectedDeliveryModel: string
  selectedLookbackPeriod: number
  selectedLeads: string[]
  createdAt?: string
  updatedAt?: string
  isClean: boolean
  selectedCap: number
  manual_segment_order: string[]
  segment_fill_type: string
}

export const ContractConfigurationInitialState = {
  contractId: null,
  contractName: '',
  contractType: '',
  contractSource: '',
  contractInstitutionId: null,
  status: '',
  isDisabledContract: false,
  purchaseType: '',
  audienceType: AudienceTypeHelper.getNone(),
  salesforceID: '',
  startDate: '',
  endDate: '',
  notes: '',
  leadCap: null,
  segmentLeadCapTotal: null,

  selectedDestinations: [],
  selectedFileFormat: FileFormat.CSV,
  selectedFields: [],
  selectedDeliveryCadence: [],
  selectedDeliveryModel: 'lead_cap_reached',
  selectedLookbackPeriod: LookBackPeriod.toNumberOfDays(
    LookBackPeriod.two_years
  ),
  selectedLeads: [],
  createdAt: null,
  updatedAt: null,
  isClean: true,
  selectedCap: null,
  manual_segment_order: [],
  segment_fill_type: 'least_delivered',
}

export const ContractConfigurationSlice = createSlice({
  name: 'contractConfigurationSlice',
  initialState: ContractConfigurationInitialState,
  reducers: {
    setContractConfiguration: (
      state,
      action: PayloadAction<Partial<ContractConfigurationState>>
    ) => {
      for (const key in action.payload) {
        if ('isClean' === key) continue
        if(key === 'selectedDeliveryModel') {
          state[key] = deliveryModelValueToKey(action.payload[key]) || action.payload[key]
        }
        else if (ContractConfigurationInitialState.hasOwnProperty(key)) {
          state[key] = action.payload[key]
        }
      }
      state.isClean =
        state.isClean && 'isClean' in action.payload && action.payload.isClean
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetContractStates, () => {
      return ContractConfigurationInitialState
    })
  },
})

export const selectContractConfiguration = createSelector(
  (state: RootState) => state.contractConfigurationSlice,
  (contractConfigurationSlice): ContractConfigurationState => {
    return contractConfigurationSlice
  }
)

export const { setContractConfiguration } =
  ContractConfigurationSlice.actions

export default ContractConfigurationSlice.reducer
