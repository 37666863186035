import {useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'

import { Permissions } from 'Configs/userPermissions'
import { useHasPermission } from 'Hooks/useHasPermission'
import { useGetUsersQuery } from 'Services/configuration/users'
import { 
  useCreateInstitutionSettingsMutation, useGetInstitutionSettingsQuery, useUpdateInstitutionSettingsMutation, 
  useGetDeletionNotificationsQuery, useCreateDeletionNotificationsMutation, useDeleteDeletionNotificationsMutation 
} from 'Services/global/institution'

export const useInstitutionSettings = () => {
  //this hook is necessary because the server/db seeding doesn't automatically create settings for an institution
  //the gist of it is that if the settings don't exist when we use the get, we 'update' with the POST method instead of the PATCH method
  const [institution_gdpr_consent, setIsGDPR] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [notifiedUsers, setNotifiedUsers] = useState<boolean[]>([])

  const { ipedId } = useParams()
  const { hasPermission: canSwitchInstitution } = useHasPermission(
    Permissions.SwitchInstitution
  )
  const { 
    data: {institution_gdpr_consent: isCurrentlyRequiringGDPR} = {institution_gdpr_consent: false}, 
    isLoading, 
    isError: isSettingsError, 
    error: {status:settingsErrorStatus} = {status: 200}, 
    refetch 
  } = useGetInstitutionSettingsQuery({ipedId})
  const { data: usersInfo, isLoading: isLoadingUserInfo } = useGetUsersQuery(
    ipedId,
    { skip: !ipedId }
  )
  const {data: deletionNotifications, isLoading: isLoadingDeletionNotifications} = useGetDeletionNotificationsQuery({ipedId})
  const isSettingsMissing = isSettingsError && settingsErrorStatus === 404
  const [updateInstitutionSettings, {isLoading: isUpdateSettingsLoading}] = isSettingsMissing 
    ? useCreateInstitutionSettingsMutation() : useUpdateInstitutionSettingsMutation()
  const [createNotifications] = useCreateDeletionNotificationsMutation()
  const [deleteNotifications] = useDeleteDeletionNotificationsMutation()
  const saveSettings = () => {
    if (canSwitchInstitution) {
      if(isCurrentlyRequiringGDPR !== institution_gdpr_consent)
        {
          updateInstitutionSettings({ipedId, institution_gdpr_consent })
        }
      const networkNotifiedUsers = deletionNotifications.map((user: any) => user.user_id)
      if (notifiedUsers !== networkNotifiedUsers) {
        const newUsers = notifiedUsers.filter((user) => !networkNotifiedUsers.includes(user))
        const oldUsers = networkNotifiedUsers?.filter((user) => !notifiedUsers.includes(user))
        if (oldUsers) {
          deleteNotifications({ipedId, user_id_list: oldUsers})
        }
        if (newUsers) {
          createNotifications({ipedId, user_id_list: newUsers})
        }
      }
    }
  }
  useEffect(() => {
    //used for changing institutions (once the fetch for the settings returns) while already on institution settings page
    if (isSettingsMissing) {
      setIsGDPR(false)
    } else if (isCurrentlyRequiringGDPR !== institution_gdpr_consent) setIsGDPR(isCurrentlyRequiringGDPR)
  }, [isSettingsError, settingsErrorStatus, isCurrentlyRequiringGDPR])
  
  useEffect(() => {
    //need to reftech when institution changes
    refetch()
  }, [ipedId])

  useEffect(() => {
    //set all the users up
    if (usersInfo) {
      const rows = usersInfo.map((user: any) => [
        user.id,
        `${user.first_name} ${user.last_name}`,
        user.email,
      ])
      setAllUsers(rows)
    }
  }, [usersInfo])

  useEffect(() => {
    if (deletionNotifications) {
      const deletionUsers = deletionNotifications?.map((user: any) => user.user_id)
      setNotifiedUsers(deletionUsers)
    }
  }, [deletionNotifications])
  
  return {
    saveSettings,
    setIsGDPR,
    setNotifiedUsers,
    institution_gdpr_consent,
    notifiedUsers,
    allUsers,
    isLoading: isLoading || isUpdateSettingsLoading,
    isNotificationsLoading: isLoadingDeletionNotifications,
    //if there is no missing settings check current state to server property, if missing settings then has only changed if it's been made true
    isChanged: (!isSettingsMissing && !!isCurrentlyRequiringGDPR !== institution_gdpr_consent) || (isSettingsMissing && institution_gdpr_consent),
  }
}