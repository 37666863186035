import React from 'react'
import { SegmentsDetails as SegmentDetailsComponent } from 'Components/shared/contracts/segments/SegmentDetails'
import { FilterBy } from 'Filter/properties'
import { Permissions } from 'Configs/userPermissions'
import { useGetAudienceTypesQuery } from 'Services/global/audienceTypes'
import { useHasPermission } from 'Hooks/useHasPermission'
import { dateRangeToYearRange } from 'Utils/dateUtils'
import { setupDefaultValues } from 'Utils/segmentFilterDefaultValues'
import AreaOfStudyConfig from 'Configs/fields/area_of_study.json'
import MajorConfig from 'Configs/fields/majors.json'
import AreaOfInterestConfig from 'Configs/fields/area_of_interest.json'
import ExpectedTransferTerm from 'Configs/fields/expected_transfer_term.json'
import { ContractSource } from 'Types/index'
import { Loading } from 'Utils/loading'
import { useGetZipCodesQuery } from 'Services/configuration/zipCodes'
import { buildFilterQuery } from 'Filter/buildFilterQuery'
import AgeConfig from 'Configs/fields/age.json'
import ExperienceConfig from 'Configs/fields/years_of_work_experience.json'
import GPAConfig from 'Configs/fields/gpa.json'
import ReligiousInstitutionConfig from 'Configs/fields/open_to_religious_institution.json'
import { selectContractConfiguration } from 'Slices/contracts/ContractConfigurationSlice'
import { useSelector } from 'react-redux'

const mapAnyToFilterValue = (anyValue: string, inputArray: string[]) => {
  let result = []

  inputArray.forEach((value: string) => {
    if (value === '') {
      result.push(anyValue)
    } else {
      result.push(value)
    }
  })

  return result
}

function mapReligiousOrAbroad(filterValue) {
  return filterValue === ''
    ? ReligiousInstitutionConfig.meta.nullLabel
    : filterValue
}

function mapFilterToDetails(myPermissions, filter, result) {
  if (!result) result = {}
  if (filter?.meta?.section && filter?.meta?.label) {
    if (!result[filter.meta.section]) result[filter.meta.section] = {}

    let value = Array.isArray(filter.value) ? [...filter.value] : filter.value
    if (filter.column === 'zip_code') {
      value = value?.map((zipCode: string) =>
        zipCode?.toString().replace(/%/, '')
      )
    }

    if (['birth_date', 'years_of_work_experience'].includes(filter.column)) {
      value = value
        ? filter.column === 'birth_date'
          ? [Math.abs(value[1]), Math.abs(value[0])]
          : [Math.abs(value[0]), Math.abs(value[1])]
        : value
      if (value === null) {
        if (filter.column === 'birth_date') {
          value = AgeConfig.meta.nullLabel
        }
        if (filter.column === 'years_of_work_experience') {
          value = ExperienceConfig.meta.nullLabel
        }
      }
    }

    if (filter.column === 'high_school_grad_date')
      value = dateRangeToYearRange(value[0], value[1])

    if (filter.column === 'country' && filter.op === 'ne')
      value = 'Outside the US'

    if (filter.column === AreaOfStudyConfig.column) {
      let areaOfStudyList = []
      value.forEach((value: string) => {
        if (value === '') {
          areaOfStudyList.push(AreaOfStudyConfig.no_selection)
        } else {
          AreaOfStudyConfig.data.forEach((areaOfStudy: string) => {
            if (value === areaOfStudy.split(' - ')[1]?.trim()) {
              areaOfStudyList.push(areaOfStudy)
            }
          })
        }
      })
      value = areaOfStudyList
    }

    if (filter.column === MajorConfig.column) {
      let majors = []
      value.forEach((value: string) => {
        if (value === '') {
          majors.push(MajorConfig.no_selection)
        } else {
          MajorConfig.data.forEach((major: string) => {
            if (value === major) {
              majors.push(major)
            }
          })
        }
      })
      value = majors
    }

    if (filter.column === AreaOfInterestConfig.column) {
      let areaOfInterestList = []
      value.forEach((value: string) => {
        if (value === '') {
          areaOfInterestList.push(AreaOfInterestConfig.no_selection)
        } else {
          AreaOfInterestConfig.data.forEach((AreaOfInterest: string) => {
            if (value === AreaOfInterest.split(' - ')[1]?.trim()) {
              areaOfInterestList.push(AreaOfInterest)
            }
          })
        }
      })
      value = areaOfInterestList
    }

    if (filter.column === ExpectedTransferTerm.column) {
      let terms = []
      value.forEach((value: string) => {
        if (value === '') {
          terms.push(ExpectedTransferTerm.no_selection)
        } else {
          ExpectedTransferTerm.data.forEach((term: string) => {
            if (value === term) {
              terms.push(term)
            }
          })
        }
      })
      value = terms
    }

    if (filter.column === 'level_of_degree_seeking') {
      value = mapAnyToFilterValue('Any', value)
    }

    if (filter.op === FilterBy.RANGE) value = `${value[0]} - ${value[1]}`

    if (value === null && filter.column === 'hs_gpa')
      value = GPAConfig.meta.nullLabel

    if (
      filter.column === 'attend_religious' ||
      filter.column === 'study_abroad'
    ) {
      value = Array.isArray(value) ? value.map(mapReligiousOrAbroad) : value
    }
    if(Array.isArray(value) && value.includes('')) {
      const emptyVal = value.indexOf('');
      value[emptyVal] = 'None Selected'
    }
    result[filter.meta.section][filter.meta.label] =
      result[filter.meta.section][filter.meta.label] &&
      result[filter.meta.section][filter.meta.label] !== 'Any'
        ? [result[filter.meta.section][filter.meta.label], value]
        : value === '' ? 'None Selected' : value

    if (
      filter?.meta?.section === 'Enhanced Candidates' &&
      !myPermissions?.includes(Permissions.ViewEnhancedCandidatesSegmentDetails)
    )
      delete result[filter?.meta?.section]
  }
  if (filter?.properties) {
    filter?.properties?.forEach((property) => {
      mapFilterToDetails(myPermissions, property, result)
    })
  }
  return result
}

export const getFilesfilterQuery = (id: string) => ({
  query: {
    filterQuery: {
      properties: [
        {
          column: 'id',
          op: 'eq',
          value: id,
        },
      ],
      operation: 'AND',
    },
  },
})

interface SegmentDetailsProps {
  contractSource: ContractSource
  chosenSegment: any
  segmentLeadCapTotal: string
  closeSegmentDetailsDialog: () => void
  segmentDetailsDialog: boolean
  handleDeactivateSegment?: () => void
  handleDuplicateSegment: (ipedId: string) => void
  handleDeleteSegment?: () => void
  handleEditSegment?: (ipedId: string) => void
  isSegmentQueue?: boolean
  disabledContract?: boolean
}

export const SegmentsDetails = ({
  contractSource,
  chosenSegment,
  segmentLeadCapTotal,
  handleDeactivateSegment,
  handleDuplicateSegment,
  handleDeleteSegment,
  handleEditSegment,
  segmentDetailsDialog,
  closeSegmentDetailsDialog,
  isSegmentQueue,
  disabledContract,
}: SegmentDetailsProps) => {
  const { hasPermission: canUpdateSegment, myPermissions } = useHasPermission(
    Permissions.UpdateSegment
  )
  const { contractName, audienceType } = useSelector(
    selectContractConfiguration
  )
  const result = setupDefaultValues(contractSource, audienceType)

  const { data: audienceTypes, isFetching: isFetchingAudienceTypes } =
    useGetAudienceTypesQuery(contractSource, { skip: !contractSource })

  /** Map the segment's filter object  */
  let segmentData = mapFilterToDetails(
    myPermissions,
    chosenSegment.filter,
    result
  ) as any
  segmentData = Object.fromEntries(
    Object.entries(segmentData).filter(
      ([key, value]) => Object.keys(value).length > 0
    )
  )

  const { data } = useGetZipCodesQuery({
        ipedId: chosenSegment.institution_id,
        page: 0,
        sortBy: 'updated_at',
        order: 'desc',
        filter: buildFilterQuery(getFilesfilterQuery(chosenSegment.zip_id)),
      }, { skip: !chosenSegment.zip_id })

  if (isFetchingAudienceTypes) return <Loading />

  const shouldUseFileName = chosenSegment.zip_id && data?.records[0]?.name

  const segmentDataWithFileName = shouldUseFileName
    ? {
        ...segmentData,
        Demographic: {
          ...segmentData.Demographic,
          Geography: data?.records[0]?.name,
        },
      }
    : segmentData

  return (
    <SegmentDetailsComponent
      contract={{ label: contractName, audience_type: audienceType }}
      audienceTypes={audienceTypes}
      chosenSegment={chosenSegment}
      segmentData={segmentDataWithFileName}
      handleDeactivateSegment={handleDeactivateSegment}
      handleDuplicateSegment={handleDuplicateSegment}
      handleDeleteSegment={handleDeleteSegment}
      handleEditSegment={handleEditSegment}
      segmentLeadCapTotal={segmentLeadCapTotal}
      contractSource={contractSource}
      segmentDetailsDialog={segmentDetailsDialog}
      closeSegmentDetailsDialog={closeSegmentDetailsDialog}
      isSegmentQueue={isSegmentQueue}
      canUpdateSegment={canUpdateSegment}
      disabledContract={disabledContract}
    />
  )
}
