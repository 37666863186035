import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from '../getBaseQuery'

interface TransformedResponse {
  records: any[]
  total_pages: number
  total_records: number
}

interface GetZipCodesResponse {
  data: {
    records: any[]
    total_pages: number
    total_records: number
    current_page: number
    current_size: number
  }
}

const ZIP_CODES = 'ZIP_CODES'

export const ZipCodesApi = createApi({
  reducerPath: 'ZipCodes',
  baseQuery,
  tagTypes: [ZIP_CODES],
  endpoints: (build) => ({
    /** Get all zip code files for the institution */
    getZipCodes: build.query<
      TransformedResponse,
      {
        ipedId: string
        page: number
        sortBy: string
        order: string
        filter?: string | object
      }
    >({
      query: ({ ipedId = null, page, sortBy, order, filter }) => ({
        url: `${ipedId ? 'institutions/' + ipedId : ''}/files?page=${page}&size=10&sort_by=${sortBy}::${order}&filter=${filter}`,
      }),
      transformResponse: (
        response: GetZipCodesResponse
      ): TransformedResponse => {
        const zipCodeFiles: TransformedResponse = {
          total_pages: response.data.total_pages,
          total_records: response.data.total_records,
          records: response.data.records,
        }
        return zipCodeFiles
      },
      providesTags: [ZIP_CODES],
    }),

    deleteZipCodes: build.mutation<
      { message: string },
      { ipedId: string; fileId: string }
    >({
      query: ({ ipedId, fileId }) => ({
        url: `institutions/${ipedId}/files/${fileId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [ZIP_CODES],
    }),

    getZipCodeFile: build.query<
      { file: any },
      { ipedId: string; fileId: string }
    >({
      query: ({ ipedId, fileId }) => ({
        url: `institutions/${ipedId}/files/${fileId}`,
      }),
    }),
  }),
})

export const {
  useGetZipCodesQuery,
  useGetZipCodeFileQuery,
  useDeleteZipCodesMutation,
} = ZipCodesApi
