import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'Utils/store'
import { multiSelectSlice } from 'Slices/multiSelectSlice'
import SliceConfig from 'Configs/fields/certificate_seeking.json'
import { ContractSource } from 'Types/contractTypes'

const contractSource = ContractSource.candidates

const transformValues = function (selectedValues) {
  return selectedValues.map((value) => (value === 'None Selected' ? '' : value))
}

export const CertificateSeekingSlice = multiSelectSlice(
  'certificate_seeking_type_list',
  'setCertificateSeeking',
  SliceConfig.values,
  SliceConfig.meta,
  transformValues
)

export const selectCertificateSeeking = createSelector(
  ({ onDemandFilter: { prospectProfile } }: RootState) =>
    prospectProfile.certificateSeeking.selectedValues,
  (
    selectedValues: string[]
  ): {
    selectedValues: string[]
    values: string[]
    audienceTypes: string[]
    label
  } => {
    return {
      selectedValues,
      values: SliceConfig.values,
      audienceTypes: SliceConfig.source[contractSource].audience_types,
      label: SliceConfig.meta.label,
    }
  }
)

export const { setCertificateSeeking } = CertificateSeekingSlice.actions

export default CertificateSeekingSlice.reducer