import { combineReducers } from '@reduxjs/toolkit'

import GpaTestScoresReducer from './GpaTestScoresSlice'
import GpaReducer from './GpaSlice'
import TestScoresReducer from './TestScoresSlice'
import GraduationYearReducer from './GraduationYearSlice'
import IntendedAreaofStudyReducer from './IntendedAreaofStudySlice'
import LevelOfDegreeSeekingReducer from './LevelOfDegreeSeekingSlice'
import LastDegreeCompletedReducer from './LastDegreeCompletedSlice'
import ProfessionalCredentialReducer from './ProfessionalCredentialSlice'
import CertificateSeekingSlice from './CertificateSeekingSlice'
import InstructionalLevelSlice from './InstructionalLevelSlice'
import StartTimeframeSlice from './StartTimeframeSlice'
import UTMMediumSlice from './UTMMediumSlice'
import UTMSourceSlice from './UTMSourceSlice'
import UTMCampaignSlice from './UTMCampaignSlice'
import CurrentlyEnrolledReducer from './CurrentlyEnrolledSlice'
import AreaOfInterestSlice from './AreaOfInterestSlice'
import AcademicDisciplineSlice from './AcademicDisciplineSlice'
import ExpectedTransferTermSlice from './ExpectedTransferTermSlice'
import ModalitySlice from './ModalitySlice'
import YearsOfWorkExperienceSlice from './YearsOfWorkExperienceSlice'
import StudyAbroadReducer from 'Slices/segments/StudyAbroad'
import ReligiousInstitutionReducer from 'Slices/segments/ReligiousInstitution'
import AthleticsSlice from './AthleticsSlice'
import ExtracurricularsSlice from './ExtracurricularsSlice'

export const prospectProfileReducer = combineReducers({
  gpaTestScores: GpaTestScoresReducer,
  gpa: GpaReducer,
  testScores: TestScoresReducer,
  graduationYear: GraduationYearReducer,
  areaOfInterest: AreaOfInterestSlice,
  academicDiscipline: AcademicDisciplineSlice,
  athletics: AthleticsSlice,
  extracurriculars: ExtracurricularsSlice,
  intendedAreaofStudy: IntendedAreaofStudyReducer,
  lastDegreeCompleted: LastDegreeCompletedReducer,
  professionalCredentials: ProfessionalCredentialReducer,
  certificateSeeking: CertificateSeekingSlice,
  instructionalLevel: InstructionalLevelSlice,
  startTimeframe: StartTimeframeSlice,
  UTMMedium: UTMMediumSlice,
  UTMSource: UTMSourceSlice,
  UTMCampaign: UTMCampaignSlice,
  levelOfDegreeSeeking: LevelOfDegreeSeekingReducer,
  currentlyEnrolled: CurrentlyEnrolledReducer,
  expectedTransferTerm: ExpectedTransferTermSlice,
  modality: ModalitySlice,
  experience: YearsOfWorkExperienceSlice,
  studyAbroad: StudyAbroadReducer,
  religiousInstitution: ReligiousInstitutionReducer
})
